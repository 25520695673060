<template>
  <div class="vue-box">
    <div class="c-panel">
      <!-- 参数栏 -->
      <div class="c-title">支付宝转账记录</div>
      <el-form :inline="true" size="mini">
        <el-form-item>
          <el-button type="success" icon="el-icon-refresh" @click="
                p.page = 1;
                f5();
              ">刷新</el-button>
        </el-form-item>
        <br />
      </el-form>
      <el-table :data="dataList" size="mini" :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ 'text-align': 'center' }">
        <el-table-column label="业务单号" prop="outBizNo" width="180px"></el-table-column>
        <el-table-column label="支付宝账号" prop="alipayAccount"></el-table-column>
        <el-table-column label="支付宝姓名" prop="alipayName"></el-table-column>
        <el-table-column label="转出金额" prop="amount">
          <template slot-scope="s">
            <el-tag type="danger" effect="dark" size="mini">￥{{ s.row.amount }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="转出商户号" prop="transferMch" width="150px"></el-table-column>
        <el-table-column label="转出商户名称" prop="transferMchName" width="150px"></el-table-column>
        <el-table-column label="转出时间" prop="time" width="150px"></el-table-column>
        <el-table-column label="转出状态" prop="status">
          <template slot-scope="s">
            <el-tag v-if="s.row.status == 1" type="success" effect="dark" size="mini">转账成功</el-tag>
            <el-tag v-if="s.row.status == 0" type="danger" effect="dark" size="mini">转账失败</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作">
          <template slot-scope="s">
            <el-button v-if="s.row.status == 0" class="c-btn" type="danger" icon="el-icon-switch-button" @click="forceLogout(s.row)">查看错误信息</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="page-box">
        <el-pagination background layout="total, prev, pager, next, sizes" :current-page.sync="p.page" :page-size.sync="p.pageSize" :total="dataCount" :page-sizes="[1, 10, 20, 30, 40, 50, 100]" @current-change="f5(true)" @size-change="f5(true)">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      p: {
        page: 1,
        limit: 10,
      },
      dataCount: 0,
      dataList: [],
    };
  },
  methods: {
    // 数据刷新
    f5: function () {
      var defaultCfg = {
        type: "get", // 默认请求类型
      };
      this.sa.ajax(
        "/v1/transferLog/list",
        this.p,
        function (res) {
          this.dataList = res.data.content; // 数据
          this.dataCount = res.data.totalElements; // 分页
        }.bind(this),
        defaultCfg
      );
    },
    forceLogout: function (row) {
      this.sa.error(row.errormsg);
    }
  },
  created: function () {
    this.f5();
  },
};
  </script>
  
  <style>
</style>
  